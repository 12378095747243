import React from 'react';
import { FormattedMessage as L } from 'react-intl';
import { useWindowSize } from 'react-use';
import Lottie from 'react-lottie';
import howWorksAnimation from 'utils/animations/web_referral_how_works.json';
import LinkButton from '../LinkButton';
import WL from 'constants/whiteLabel';
import Typography from '../Typography';
import s from './HowItWorks.module.css';

const HowItWorks = () => {
  const { width } = useWindowSize();

  const shouldDisplayAnimation = width >= 768;

  return (
    <div className={s.root}>
      <Typography type="title">
        <L id="how_it_works" defaultMessage="How it works" />
      </Typography>
      <div className={s.cards}>
        <div className={s.card}>
          <div className={s.glowSpot}>{' '}</div>
          <div className={s.cardBorder}>
            <div className={s.cardNumber}>1</div>
            <div className={s.cardContent}>
              <div className={s.cardText}>
                <Typography type="caption">
                  <L id="Referral.HowItWorks.caption1" defaultMessage="Get your referral link in account" />
                </Typography>
              </div>
            </div>
          </div>
        </div>
        <div className={s.card}>
          <div className={s.glowSpot}>{' '}</div>
          <div className={s.cardBorder}>
            <div className={s.cardNumber}>2</div>
            <div className={s.cardContent}>
              <div className={s.cardText}>
                <Typography type="caption">
                  <L id="Referral.HowItWorks.caption2" defaultMessage="Invite new users using refferal link or promocode" />
                </Typography>
              </div>
            </div>
          </div>
        </div>
        <div className={s.card}>
          <div className={s.glowSpot}>{' '}</div>
          <div className={s.cardBorder}>
            <div className={s.cardNumber}>3</div>
            <div className={s.cardContent}>
              <div className={s.cardText}>
                <Typography type="caption">
                  <L id="Referral.HowItWorks.caption3" defaultMessage="Earn every time your referral extend partnership with platform" />
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
      {shouldDisplayAnimation && (
        <div className={s.animationWrap}>
          <Lottie options={{animationData: howWorksAnimation}} />
        </div>
      )}
    </div>
  );
};

export default HowItWorks;
