import React from 'react';
import { FormattedMessage as L } from 'react-intl';
import ExpandedPanels from 'components/ExpandedPanels';
import Link from 'components/Link';
import Typograhy from '../Typography';
import s from './Faq.module.css';

<L id="" defaultMessage="" />

const Faq = () => {
  const expandedPanelsContent = [
    {
      title: <L id="Referral.Faq.Question1" />,
      innerContent: <L id="Referral.Faq.Answer1" values={{
        strong: <b><L id="Referral.Faq.Answer1_2" /></b>,
      }} />,
    },
    {
      title: <L id="Referral.Faq.Question8" />,
      innerContent: <L id="Referral.Faq.Answer8" values={{
        br: <br />,
      }} />,
      innerContentList: [
        <L key="Referral.Faq.Answer8_1" id="Referral.Faq.Answer8_1" values={{
          Reddit: <Link external href="https://www.reddit.com/r/cryptomining/">Reddit</Link>,
          Bitcointalk: <Link external href="https://bitcointalk.org/">Bitcointalk</Link>,
          MiningClub: <Link external href="https://miningclub.info/">Mining Club</Link>,
        }} />,
        <L key="Referral.Faq.Answer8_2" id="Referral.Faq.Answer8_2" values={{
          Facebook: <Link external href="https://www.facebook.com/groups/214158459149008">Facebook</Link>,
          Twitter: <Link external href="https://twitter.com/home">Twitter</Link>,
          YouTubeChannel: <Link external href="https://www.youtube.com/c/HiveOS/videos">YouTube</Link>,
        }} />,
        <L key="Referral.Faq.Answer8_5" id="Referral.Faq.Answer8_5" />,
        <L key="Referral.Faq.Answer8_3" id="Referral.Faq.Answer8_3" />,
        <L key="Referral.Faq.Answer8_4" id="Referral.Faq.Answer8_4" values={{
          Medium: <Link external href="https://medium.com/">Medium</Link>,
          Quora: <Link external href="https://www.quora.com/">Quora</Link>,
        }} />,
      ],
    },
    {
      title: <L id="Referral.Faq.Question2" />,
      innerContent: <L id="Referral.Faq.Answer2" values={{
        br: <br />,
        strong: <b><L id="Referral.Faq.Answer2_4" /></b>,
      }} />,
      innerContentList: [
        <L key="Referral.Faq.Answer2_2" id="Referral.Faq.Answer2_2" />,
        <L key="Referral.Faq.Answer2_3" id="Referral.Faq.Answer2_3" />,
      ],
    },
    {
      title: <L id="Referral.Faq.Question3" />,
      innerContent: <L id="Referral.Faq.Answer3" />,
    },
    {
      title: <L id="Referral.Faq.Question4" />,
      innerContent: <L id="Referral.Faq.Answer4" />,
    },
    {
      title: <L id="Referral.Faq.Question5" />,
      innerContent: <L id="Referral.Faq.Answer5" />,
    },
    {
      title: <L id="Referral.Faq.Question6" />,
      innerContent: <L id="Referral.Faq.Answer6" />,
    },
    {
      title: <L id="Referral.Faq.Question7" />,
      innerContent: <L id="Referral.Faq.Answer7" />,
    },
  ];
  return (
    <div className={s.root}>
      <Typograhy type="title">
        <L id="Referral.Faq.title" defaultMessage="FAQ" />
      </Typograhy>
      <ExpandedPanels content={expandedPanelsContent} />
    </div>
  );
};

export default Faq;

