import React from 'react';
import Link from 'components/Link';
import cx from 'classnames';
import s from './LinkButton.module.css';

const LinkButton = ({ children, className='', ...linkProps }) => (
  <Link {...linkProps} className={cx(s.btn, className)}>
    {children}
  </Link>
);

export default LinkButton;
