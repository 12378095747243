import React from 'react';
import { FormattedMessage as L } from 'react-intl';
import Lottie from 'react-lottie';
import { useWindowSize } from 'react-use';
import cx from 'classnames';
import LinkButton from '../LinkButton';
import referralDesktopAnimation from 'utils/animations/web_referral.json';
import referralMobileAnimation from 'utils/animations/web_referral_mobile.json';
import WL from 'constants/whiteLabel';
import IntroFeatures from 'components/referral/IntroFeatures';

import s from './intro.module.css';

const Intro = () => {
  const { width } = useWindowSize();

  const isMob = width < 768;
  const isDesktop = width >= 768;

  return (
    <section className={s.wrapper}>
      <div className={s.imgContainer}>
      {
          isDesktop ? (
            <div className={cx(s.animationWrap)}>
              <Lottie options={{animationData: referralDesktopAnimation}} />
            </div>
          ) : null
        }
        {
          isMob ? (
            <div className={cx(s.animationWrap)}>
              <Lottie options={{animationData: referralMobileAnimation}} />
            </div>
          ) : null
        }
        <div className={s.content}>
          <div className={s.textWrapper}>
            <h2 className={s.title}>
              <L id="Referral.Intro.title" defaultMessage="Partnership with Hive" />
            </h2>
            <div className={s.text}>
              <div>
                <L id="Referral.Intro.subtitle1" defaultMessage="Increase your income with Hive." />
              </div>
              <div>
                <L id="Referral.Intro.subtitle2" defaultMessage="Invite your friends and earn real cryptocurrency!" />
              </div>
            </div>
            <div className={s.action}>
              <LinkButton external noRef href={WL.referralLink}>
                <L id="GetYourRefferalLink" defaultMessage="Get your refferal link" />
              </LinkButton>
            </div>
          </div>
          <IntroFeatures />
        </div>
      
      </div>
    </section>
  );
};

export default Intro;
