import React, { Component } from "react";
import _get from 'lodash/get';
import cx from 'classnames';
import WL from 'constants/whiteLabel';
import Layout from 'components/layout';
import Intro from 'components/referral/Intro';
import HowItWorks from 'components/referral/HowItWorks';
import ContactUs from 'components/referral/ContactUs';
import MonthReferrals from 'components/referral/MonthReferrals';
import Faq from 'components/referral/Faq';
import Bottom from 'components/Bottom/Bottom';
import gs from 'pages/pages.module.css';

class Referral extends Component {
  render() {
    return (
      <div className={gs.referralBg}>
        <Layout withHeaderWrapper={false} lang={_get(this.props, 'pageContext.lang', 'en')} page="referral">
          <Intro />

          <div className={cx(gs.content, gs.referral, gs.referralBg)}>
            <div className={cx(gs.wrapper)}>
              <HowItWorks />
              <ContactUs />
              <MonthReferrals />
              <Faq />
            </div>
          </div>
          <div className={cx(gs.wrapper)}>
            <Bottom isReferral/>
          </div>
        </Layout>
      </div>
    );
  }
}

export default Referral;
