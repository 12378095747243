import React from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import _get from 'lodash/get';
import CollapseBlock from 'components/CollapseBlock';
import s from './ExpandedPanels.module.css';

const ExpandedPanels = ({ content }) => {
  return (
    <div className={s.propertiesItems}>
      {_map(content, (item, idx) => {
        return (
          <div className={s.propertiesItem} key={`expanded-panel-item-${idx}`}>
            <CollapseBlock
              title={_get(item, 'title')}
              titleClass={s.propertiesItemsTitle}
              openTitleClass={s.propertiesItemsTitleOpen}
              withoutContainerStyle
            >
              <div className={s.propertiesItemText}>
                {_get(item, 'innerContent', null)}

                {
                  _get(item, 'innerContentList', null) ? (
                    <ul>
                      {
                        _map(_get(item, 'innerContentList', []), (listItem, listItemIdx) => (
                          <li key={`expanded-panels-list-${listItemIdx}`}>{listItem}</li>
                        ))
                      }
                    </ul>
                  ) : null
                }
              </div>
            </CollapseBlock>
          </div>
        );
      })}
    </div>
  );
};

ExpandedPanels.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node,]).isRequired,
      innerContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node,]).isRequired
    }),
  ).isRequired,
};

export default ExpandedPanels;

