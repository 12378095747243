import React from 'react';
import { FormattedMessage as L } from 'react-intl';
import glowAnimationImg from 'utils/images/glow_animation.svg';
import LinkButton from '../LinkButton';
import Typography from '../Typography';
import s from './ContactUs.module.css';

const ContactUs = () => {
  return (
    <div className={s.root}>
      <div className={s.text}>
        <Typography type="title" className={s.title}>
          <L id="Referral.ContactUs.title" defaultMessage="Do you want to integrate as Vendor or Retail Shops Partnership?" />
        </Typography>
        <LinkButton external noRef href="mailto: brain@hiveos.farm" className={s.btn}>
          <L id="Partnership.contactUs" defaultMessage="Contact us" />
        </LinkButton>
      </div>
      <div className={s.animationWrap}>
        <img src={glowAnimationImg} />
      </div>
    </div>
  );
};

export default ContactUs;
