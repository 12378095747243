import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';
import { FormattedMessage as L } from 'react-intl';
import { useWindowScroll } from 'react-use';
import Lottie from 'react-lottie';
import referralRewardsAniumation from 'utils/animations/web_referral_rewards.json';
import WL from 'constants/whiteLabel';
import LinkButton from '../LinkButton';
import Typograhy from '../Typography';
import s from './MonthReferrals.module.css';

const MonthReferrals = () => {
  const ref = useRef(null);

  const [isScrolled, setIsScrolled] = useState(false);
  const [isStopped, setIsStopped] = useState(true);
  const [y, setY] = useState(0);
  const {x: winX, y: winY} = useWindowScroll();

  useEffect(() => {
    if (y && winY >= y - 200 && !isScrolled) {
      setIsScrolled(true);
      setIsStopped(false);
    }
  }, [winY]);

  useLayoutEffect(() => {
    if (ref.current) {
      const boundingRect = ref.current.getBoundingClientRect();
      setY(boundingRect.y + window.pageYOffset);
    }
  }, [ref.current]);

  return (
    <div className={s.root} ref={ref}>
      <Typograhy type="title" className={s.title}>
        <L id="Referral.MonthReferrals.title" defaultMessage="Monthly users earnings on referrals" />
      </Typograhy>
      <div className={s.animationWrap}>
        <Lottie
          options={{animationData: referralRewardsAniumation, loop: false, autoplay: false}}
          isStopped={isStopped}
        />
      </div>
      <div className={s.buttonWrap}>
        <LinkButton external noRef href={WL.referralLink}>
          <L id="GetYourRefferalLink" defaultMessage="Get your refferal link" />
        </LinkButton>
      </div>
    </div>
  );
};

export default MonthReferrals;

