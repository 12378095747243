import React from 'react';
import { FormattedMessage as L } from 'react-intl';
import Typography from '../Typography';
import percentsImg from 'utils/images/referral_percents.svg';
import payoutsDailyImg from 'utils/images/referral_payout_daily.svg';
import payoutsMinImg from 'utils/images/referral_payout_minimum.svg';
import s from './IntroFeatures.module.css';

const IntroFeatures = () => (
  <div className={s.wrapper}>
    <div className={s.flowBlock}>
      <div className={s.flowItem}>
        <img src={percentsImg} alt="icon" />
        <Typography type="text" className={s.text}>
          <L id="Referral.IntroFeatures.text1" defaultMessage="Get 10% from your friends payments" />
        </Typography>
      </div>
      <div className={s.flowItem}>
        <img src={payoutsDailyImg} alt="icon" />
        <Typography type="text" className={s.text}>
          <L id="Referral.IntroFeatures.text2" defaultMessage="Earn money each month till the friend is using Hive" />
        </Typography>
      </div>
      <div className={s.flowItem}>
        <img src={payoutsMinImg} alt="icon" />
        <Typography type="text" className={s.text}>
          <L id="Referral.IntroFeatures.text3" defaultMessage="Unlimited number of referrals, no hidden restrictions" />
        </Typography>
      </div>
    </div>
  </div>
);

export default IntroFeatures;
