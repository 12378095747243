import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import s from './typography.module.css';

const Typography = ({ children, type = 'text', className }) => (
  <Fragment>
    {type === 'title' && (
      <h2 className={cx(s.title, className)}>{children}</h2>
    )}
    {type === 'text' && (
      <div className={cx(s.text, className)}>{children}</div>
    )}
    {type === 'caption' && (
      <div className={cx(s.caption, className)}>{children}</div>
    )}
  </Fragment>
);

Typography.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['title', 'text', 'caption']).isRequired,
  className: PropTypes.string,
};

export default Typography;
